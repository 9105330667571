.searchbar {
  height: 30px;
  width: 320px;
  position: absolute;
  left: 40px;
  top: 4px;

  background: transparent;
  border: none;
  font-size: 15px;
  outline: none;

}

.searchbox {
  height: 40px;
  width: 0;
  background-color: rgba(0,0,0,0.1);
  position: absolute;
  right: 100px;
  top: 50%;
  border-radius: 2px;
  transform: translateY(-50%);
  transition: width 300ms;
  overflow: hidden;
  z-index: 100;
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0,0,0,0.4);
    opacity: 1; /* Firefox */
  }
}

  .searchboxOpen {
    width: 400px;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(0,0,0,0.4);
  }

  :-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(0,0,0,0.4);
  }


.clearButton {
  position: absolute !important;
  right: 6px;
  top: -3px;
  display: none;

}

.searchIcon {
  position: absolute;
  left: 10px;
  top: 8px;
  opacity: 0.4;
  transition: opacity 300ms !important;
  z-index: 80;
}
.searchIconWithText{
  opacity: 1;
}

.searchButton {
  position: absolute !important;
  right: 100px;
  top: 10px;
}
.searchButtonOpen{
  display: none !important;
}


.root {
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #DBDBDB;
}
.content {
  max-width: 1208px;
  margin: auto;
  position: relative;
  padding: 24px;
  font-size: 16px;
  box-sizing: border-box;
  min-height: 68px;
}
.contentwide {
  max-width: 1650px;
  margin: auto;
  position: relative;
  padding: 24px;
  font-size: 16px;
  box-sizing: border-box;
  min-height: 68px;
}
.button {
  float: right;
  position: relative;
  bottom: 12px;
}
