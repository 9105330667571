.projectdiv {
  overflow: hidden;
  overflow-y: scroll;
  height: 550px;
}
.myhead {
  color: black;
}
.answerPercentageWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}