.root {
    flex-direction: column;
    padding-bottom: 24px;
    margin: 0 auto;
    width: 75%;
    padding-left: 25%;
}
.item {
    display: inline-block;
    width: 50%;
    padding: 16px 0;
    color: rgba(0,0,0,0.8);
}
.divider {
    flex: 1 0 100%;
    width: 65%;
    margin-top: 10px;
    color: rgba(0,0,0,0.3)
}
.header {
    font-size: 18px;
    margin-top: 40px;
    color: rgba(0,0,0,0.8);
}