@media (max-width: 768px) {

  #headerTitle {
    margin: auto !important;
  }

  #externalPhoto {
    width: 100%;
  }

  #externalUl {
    padding-left: 0;
  }

  #tbExternPaperMain {
    margin-top: -30px;
    margin-bottom: 0px;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;

  }

  #tbExternPaper {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0px;
  }

  #tbExternPaperFeedback {
    margin-top: -30px;
    box-sizing: border-box;
    margin-bottom: 0px;
  }

  #answerButton {
    width: 100%
  }

  #sendresultsButton {
    width: 100%
  }

  .appModule {
    padding: 0 0 !important;
  }

  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  #Root {
    min-width: 10px !important;
  }
  .dropdown{
    right: 200px;
  }
}
.root {
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}
.dropdown {
  background-color: transparent;
  right: 0px;
  top: 0;
  position: absolute;
}