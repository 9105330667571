.root {
  padding: 8px 16px;
  display: flex;
}
.cardLeft {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 8px;
  flex: 1 0 auto;
  padding-right: 16px;
  padding-left: 16px;
}
.cardRight {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 8px;
  flex: 0 0 376px;
  padding: 16px;
  position: sticky;
  top: 0px;
  //height: 940px'
  height: 150px;
}
