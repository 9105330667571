@use "../../lib/themes/theme.scss" as theme;
.root {
  margin-top: 64px;
  width: 100%;
  background-color: theme.$primary;
  position: fixed;
  z-index: 1101;
  padding-left: 48px;
}

.trend {
  padding: 96px 16px 32px;

}

.cols {
  flex-direction: column;
  padding: 64px 16px 32px;
}
.block {}
.paper.paper {
  padding: 16px;
}