.root {
  padding: 32px 10px;
  width: 100%;
  margin: auto;
}
.rootdesktop {
  padding: 32px 10px;
  width: 1000px;
  margin: auto;
}
.rootmobile {
  padding: 32px 10px;
  width: 350px;
  margin: auto;
}
.block {}
.paper.paper {
  padding: 16px;
}
.preview {
  width: 60%;
  float: left;
  padding-right: 44px;
  margin-bottom: 32px;
  border-right: 1px solid #DBDBDB; }

.info {
  width: 30%;
  float: right;
  padding-left: 16px;
  margin-top: 46px;
  position: relative; }

.previewView {
  width: 100%;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87); }

.myNetjes {
  padding: 4px 0; }

.check {
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #DBDBDB; }

.fixedContainer {
  position: fixed; }

.buttonNetjes:first-child {
  margin: 4px 8px 4px 0; }

.buttonNetjes:last-child {
  margin: 4px 0 4px 8px; }

