@use "../../../lib/themes/theme.scss" as theme;
.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: theme.$zindex + 1;
}
.root {
  margin-top: 64px;
  width: 100%;
  background-color: theme.$primary;
  position: fixed;
  z-index: 1101;
  padding-left: 48px;
}
