@use "../../lib/themes/theme.scss" as theme;
.root {
  display: flex;
  align-items: center;
  height: 64px;
}

.crumb {
  color: theme.$highlightText;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 400;
  padding-left: 10px;
  &:not(:first-child):before {
    position: absolute;
    left: 14px;
    top: 16px;
    bottom: 16px;
    display: inline-block;
    width: 1px;
    opacity: 0.24;
    background-color: #272D35;
  }
}
.crumb svg{
  color: theme.$highlightText;
}
.crumbText {
  max-width: 500px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  margin: 0;
  line-height: 64px;
}
.input {
  background: rgba(0,0,0,0.15);
  border-radius: 3px;
  width: 300px;
}
.searchIcon {
  position: absolute;
  left: 4px;
  top: 12px;
  width: 26px;
  height: 26px;
  opacity: 0.8;
}
.search {
  position: relative;
}
.backLink.backLink {
  margin: 0 8px 0 -8px;
  display: inline-block;
  vertical-align: middle;
}