.root {
  display: flex;
  align-items: center;
}
.rootnopages {
  display: flex;
  align-items: center;
  height: 48px;
}
.label {
  flex: 0 0 auto;
  user-select: none;
}
.button {
  flex: 0 0 auto;
}
