@use "../../lib/themes/theme.scss" as theme;
@media (min-width: 1186px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 784px !important;
    margin: auto;
  }
}

@media (max-width: 1185px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 784px !important;
    margin: auto;
  }
}

@media (max-width: 810px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 342px !important;
    margin: auto;
  }

  .card {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media (max-width: 450px) {

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw !important;
    margin: auto;
  }

  .card {
    width: calc(100vw - 50px) !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
.vvlogo {
  height: 64px;
  position: fixed;
  right: 16px;
  padding: 8px;
  box-sizing: border-box;
  top: 0;
  z-index: 1200;
}
.card {
  display: inline-block;
  position: relative;
  height: 348px;
  width: 342px;
  border-radius: 2px;
  background-color: #FFFFFF;
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 60px;
}
.cardbg {
  height: 152px;
  width: 342px;
  border-radius: 2px 0 0 2px;
  background-color: #F8F8F8;
}
.cardbutton {
  height: 52px;
  width: 100%;
  border-radius: 2px;
  background-color: theme.$primary;
  position: absolute;
  bottom: 0;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
  color: theme.$highlightText;
}
.button {
  position: fixed;
  top: 40px;
  right: 36px;
  z-index: 1102;
}
.dropdown{
  right: 0px;
}
.tab.tab{
  color: theme.$highlightText;
}