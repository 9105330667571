@use "../../lib/themes/theme.scss" as theme;
.wrapper {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
}
.dragIcon {
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}
.deleteButton {
  margin-left: 8px;
  margin-right: 8px;
}
.checked {
  color: theme.$green;
  &bar {
    background-color: theme.$green;
  }
}
.bar {}
