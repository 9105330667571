.root {
  margin-top: 64px;
  width: 100%;
  background-color: #FFDC4A;
  position: fixed;
  z-index: 1101;
  padding-left: 48px;
}
.buttonContainer {
  width: 100%;
  text-align: center;
}
