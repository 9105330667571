@use "../../lib/themes/theme.scss" as theme;
.root {

}
.title {
  color: theme.$highlightText;
  margin-top: 40px;
}
.titleHighlight {
  margin-top: 40px;
  color: theme.$highlightText,
}
.subtitle {
  font-size: 0.8em;
}
.highlight {
  color: theme.$highlightText;
  background: theme.$highlight;
  margin: 0 -16px;
  padding: 8px 16px;
  margin-left: -22px;
}
