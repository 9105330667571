.paper.paper {
  position: relative;
  width: 450px;
  padding: 24px;
}
.button {
  display: block;
  width: 100%;
  margin-top: 48px;
  margin-bottom: 16px;
}
.message {
  margin-top: 16px;
  margin-bottom: 16px;
}
