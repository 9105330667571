@media (max-width: 768px) {

    #list {
        width: 100vw !important;
    }

    #logo {
        margin-left: 60px;
    }

    #closeMenu {
        visibility: visible !important;
    }
}
.list {
    flex: 0 1 auto;
    min-width: 356px;
}
.SideMenuHeader {
    height: 71.5px;
    padding: 15px 16px;
}
.logo{
    height: 100%;
}