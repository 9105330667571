.root {
  padding-top: 122px;
}
.container {
  border-bottom: 1px #EEE solid;
  padding: 16px 40px;
}
.item {
  display: inline-block;
  width: 50%;
  margin: 16px 0;
  word-break: break-all;
  vertical-align: top;
}
.sectorInput {
  height: auto;
  display: initial;
}
