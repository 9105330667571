@use "../../lib/themes/theme.scss" as theme;
.root {
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}
.highlight {
  &:before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid  + theme.$primary;
  }
}
.cardHeader {
  cursor: pointer;
  &:last-child {
    padding-bottom: 16px;
  }
  overflow: hidden;
  &:nth-child(n+1) {
    width: calc(100vw - 750px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.cardContent {
  padding-top: 0;
  padding-left: 64px;
  padding-right: 64px;
}
.cardActions.cardActions {
  position: absolute;
  top: 6px;
  right: 6px;
  padding:0px
}
.expand {
  transform: rotate(0deg);
}
.expandOpen {
  transform: rotate(180deg);
}
