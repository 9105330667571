@use "../../../lib/themes/theme" as theme;
.root {
  margin-top: 64px;
  width: 100%;
  background-color: theme.$primary;
  position: fixed;
  z-index: theme.$zindex;
  padding-left: 48px;
}
.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: theme.$zindex;
}
