.app {}
.root {
  flex-direction: column;
  padding: 32px 16px;
}
.block {}
.paper {
  position: relative;
  padding: 32px;
  margin-bottom: 30px;
}
.unselectedAnswer {
  background-color: #F5F5F5;
  padding: 16px;
  margin-top: 16px;
  border: 1px solid #696969;
  border-radius: 5px;
  color: #292929;
  cursor: pointer;
  user-select: none;
}
.outcomeText {
  padding-bottom: 16px;
  margin-top: -32px;
}
.toolboxMeetingBox {
  display: inline-block;
  width: 100%;
  background-color: #272D35;
  margin-top: 16px;
}
.toolboxMeetingBoxH3 {
  color: #FFDC4A;
  font-size: 20px;
  padding: 16px 16px 10px;
}
.toolboxMeetingLeft {
  width: 96px;
  color: #FFFFFF;
  opacity: 0.6;
  float: left;
  padding-left: 16px;
  padding-bottom: 10px;
}
.toolboxMeetingRight {
  color: #FFFFFF;
  float: left;
  padding-left: 8px;
  padding-bottom: 10px;
}