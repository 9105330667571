.text {
    margin-bottom: 0px;
}
.maxEmployees {
    display: block;
    width: 100%;
    margin-right: 8px;
}
.subscriptionHolder {
    display: block;
    width: 40%;
    margin-right: 8px;
}
.extraMaxEmployees {
    display: inline-block;
    width: 45%;
    margin-right: 5%;
    vertical-align: middle;
}
.extraPrice {
    display: inline-block;
    width: 45%;
    margin-right: 5%;
    vertical-align: middle;
}
.hidden {
    display: none;
}