@use "../../../../lib/themes/theme.scss" as theme;
.expand {
  transform: rotate(0deg);
}
.expandOpen {
  transform: rotate(180deg);
}
.tableCell {
  border-bottom: none;
  color: rgba(0,0,0,0.87);
}
.h4 {
  height: 15px;
  width: 352px;
  color: rgba(0,0,0,0.87);
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}
.preview {
  width: 48px;
  height: 48px;
  padding: 8px;
  cursor: pointer;
  float: left;
}
.inspectionComment {
  background: theme.$primary;
}