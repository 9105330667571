
.itemTitle {
    color:black;
    opacity: 0.38;
    font-family: 'Roboto';	
    font-size: 12px;
    padding-bottom: 4px;
}

.itemDescription {
    font-family: 'Roboto';
    font-size: 15px;
}