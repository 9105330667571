.body {
  &:after {
    height: 48px;
    display: block;
  }
}
.toolbar {
  display: table;
  align-items: center;
}
.table {
  table-layout: fixed;
  overflow: visible;
}
.actions {
  position: relative;
  top: 1px;
  padding-right: 0 !important;
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}