.rootRoot{
  text-align: right;
  transition: margin-bottom 250ms ease-out;
  border-radius: 25px;
}
.collapsedRootRoot{
  margin-bottom: -72px;
}
.root.root{
  position: relative;
  text-align: left;
  display: inline-block;
  transition:
          width 150ms ease-in 100ms, max-height 150ms ease-in 100ms, border-radius 150ms ease-in, transform 250ms ease-out;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
  padding: 0;
}
.collapsedRoot.collapsedRoot{
  width: 56px;
  max-height: 56px;
  border-radius: 28px;
  transform: translateX(56px) translateY(-52px);
  transition:
          width 150ms ease-in, max-height 150ms ease-in, border-radius 150ms ease-in, transform 250ms ease-out 100ms;
  background-color: #00000000;
}
.rootRoot .collapsedRoot{
  border-radius: 50px;
}
.topSection{
  padding: 24px;
  white-space: nowrap;
  background-color: #272D35;
  border-radius: 4px 4px 0 0;
& > h2{
  color: #FFDC4A;
  margin: 0;
}
& > p{
  color: white;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
}
}
.collapsedTopSection {
  background-color: #00000000;
}
.topSectionText{
  opacity: 1;
  transition: opacity 80ms ease-out 200ms;
  user-select: none;
}
.collapsedTopSectionText{
  transition: opacity 80ms ease-out;
  opacity: 0;
}
.bottomSection{
  padding: 24px;
}
.collapsedBottomSection {
  opacity:1;
}
.button2{
  margin: 0 0 -16px 0;
}
.items{
  margin: -16px -24px 16px -24px;
}
.shortcutIcon{
  // color: theme.palette.primary.A500
}
.closeButton.closeButton{
  position: absolute;
  right: 16px;
  top: 16px;
  transition: right 200ms, top 200ms, width 200ms, height 200ms;
  background-color: #272D35;
  color: #ffff;
}
.collapsedCloseButton.collapsedCloseButton{
  right: 0px;
  top: 0px;
  width: 56px;
  height: 56px;
}
.collapseItem{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: white;
  transition:
          transform 300ms ease-out, opacity 300ms ease-in-out !important;
}
.rotate{
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) rotate(180deg);
  transition:
          transform 300ms ease-in, opacity 300ms ease-in-out !important;
}
.buttonBody{
  color: black;
}
