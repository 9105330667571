$primary: #13294b !default;
$secondary: rgb(0, 225, 144) !default;
$highlight: #13294b !default;
$highlightText: #FFFFFF !default;
$text: #5E646D !default;
$textDark: #272D35 !default;
$error: red !default;
$green: green !default;
$border: #ECF1F4 !default;
$zindex: 1101 !default;
$selectGrey: #eee !default;
$faintBlack: #272D35 !default;
$lightGreyBackground: #f5f5f5 !default;
$spacing: 8px !default;
$tabsColor: #9f9f9f !default;

.drawerButtonColor.drawerButtonColor{
    color:$secondary;
}
.secondaryButton.secondaryButton {
    color: $secondary;
    &:hover {
        background-color: $selectGrey;
    }
}

:export {
    primaryColor: $primary;
    secondaryColor: $secondary;
    faintBlackColor: $faintBlack;
    highlightTextColor: $highlightText;
    whiteTextColor: $lightGreyBackground;
    greyTextColor: $text;
    mainTabsColor: $tabsColor;
    mainTabsSelectedColor:  $secondary;
}