.myItemTitle {
    font-family: 'Roboto';
    font-size: 12px;
    padding-bottom: 4px;
}

.abonnee-resend-activation-button {
    display: block;
    margin: 16px 0;
}
.root {
    flex-direction: column;
    padding-bottom: 24px;
    margin: 0 auto;
    width: 90%;
}
.paper{
    padding: 16px;
}
.item {
    display: inline-block;
    width: 50%;
    padding: 16px 0;
    color: rgba(0,0,0,0.8);
}
.divider {
    flex: 1 0 100%;
    width: 65%;
    margin-top:10px;
    color: rgba(0,0,0,0.3);
}