@use "../../lib/themes/theme.scss" as theme;
.appBar.appBar {
  position: relative;
  padding-right: 8px;
  background: #333b45;
  color: #fff;

}
.appBarButton.appBarButton {
  margin: 0 16px 0 8px;
  color: #fff;
}
.content {
  padding-top: 24px;
}
.flex {
  flex: 1;
}
.sectorsSelect {
  min-width: 125px;
}
.inspectionInfoContent {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  margin-top: 48px;
  margin-bottom: 32px;
}
.inspectionInfoIcon {
  flex: 0 0 auto;
  margin: 16px;
  font-size: 11px;
  text-align: center;
}
.inspectionInfoForm {
  flex: 1 0 auto;
  width: 700px;
  margin-left: 16px;
  margin-right: 72px;
}
.editors {
  background: theme.$faintBlack;
}
