@media (max-width: 768px) {

  #tbExternTitle {
    margin-left: -48px !important;
    text-align: center;
    width: 100%;
  }

  #externalPhoto {
    width: 100%;
  }

  #externalUl {
    padding-left: 0;
  }

  #tbExternPaperMain {
    margin-top: -30px;
    margin-bottom: 0px;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;

  }

  #tbExternPaper {
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 0px;
  }

  #tbExternPaperFeedback {
    margin-top: -30px;
    box-sizing: border-box;
    margin-bottom: 0px;
  }

  #answerButton {
    width: 100%
  }

  #sendresultsButton {
    width: 100%
  }

  .appModule {
    padding: 0 0 !important;
  }

  body {
    max-width: 100%;
    overflow-x: hidden;
  }

  #Root {
    min-width: 10px !important;
  }

  #stepper {
    width: 100%;
  }

  #checklist {
    width: 30% !important;
  }

  #expandbutton {
    margin-top: -10px !important;
  }
}

@media (max-width: 405px) {

  #expandbutton2 {
    margin-top: 25px !important;
  }
}

@media (max-width: 390px) {

  #weatherblock {
    margin-left: 15px !important;
  }

  #weatherlist {
    margin-top: 15px !important;
  }
}
.root {
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}
.paper {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 30px;
}
.item {
  margin-bottom: 16px;
}
.stepperWrapper {
  background-color: #ffff;
}
.stepper {
  margin: auto;
}
.stepper > div{
  padding: 24px;
}
.sectorInput {
  height: auto;
  display: initial;
}
.divider3 {
  margin-top: 18px;
  margin-bottom: 0px;
  flex: 3 0 100%;
}
.button {
  width: 100%;
  height: 64px;
}
.menuItemEmployees {
  margin: 8px;
  padding: 8px;
}
.projectDropDown{
  z-index: 999;
}