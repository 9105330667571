.root {
  padding-top: 122px;
}
.item {
  display: inline-block;
  padding: 16px 0;
  width: 100%;
}
.container {
  border-bottom: 1px #EEE solid;
}
