.root {
  flex-direction: column;
  padding: 32px 16px;
}
.block {}
.paper {
  position: relative;
  padding: 32px;
  margin-bottom: 30px;
}
.title {
  color: black;
}
