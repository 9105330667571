@use "../../../lib/themes/theme.scss" as theme;
.button {
  float: right;
  position: relative;
  bottom: 12px;
}
.root {
  margin-top: 64px;
  width: 100%;
  background-color: theme.$primary;
  position: fixed;
  z-index: 1101;
  padding-left: 48px;
}
