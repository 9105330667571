.root.root {
  display: flex;
  align-items: center;
}
.mypaper.mypaper {
  padding: 0;
}
.rootnopages {
  display: flex;
  align-items: center;
  height: 48px;
 }
.label {
  flex: 0 0 auto;
  user-select: none;
 }
.button {
  flex: 0 0 auto;
 }
.menupopup.menupopup {
  padding: 0;
}
