@use "../../lib/themes/theme.scss" as theme;
.root {
  position: relative;
}
.items {
  margin-top: 8px;
  display: inline-block;
  width: 100%;
}
.item {
  margin-top: 16px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 8px;
  }
}
.addButton.addButton {
  margin: 16px 0 0 0px;
  display: inline-block;
  color: theme.$secondary;
  &:hover {
    background-color: theme.$selectGrey;
  }
}
