@use "../../lib/themes/theme" as theme;
.root {
  padding: 0;
  width: 160px;
  height: 160px;
}
.wrapper {
  padding: 0;
  width: 160px;
  height: 160px;
  position: relative;
&:last-child {
  padding-bottom: 0;
}
}
.input {
  opacity: 0;
  display: none;
  position: absolute;
}
.button {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
}
.preview {
  position: absolute;
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  object-fit: contain;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  transition: opacity 150ms linear, transform 150ms ease-out;
}
.previewLoading {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.8);
}
.label {
  width: 160px;
  height: 160px;
  display: block;
  cursor: pointer;
}
.labelWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: theme.$secondary;
}
.uploadIcon {
  color: theme.$secondary;
}