@import '../../variables.scss';

@keyframes fadeInContent {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.AppModule {
    position: relative;
    padding-top: 64px;

    &:before {
        content: '';
        position: relative;
        display: none;
        margin-bottom: -20px;
        background-color: color(primary);
        top: 0px;
        left: 0px;
        right: 0px;
        height: 64px;
        transform-origin: 50% 0%;
        opacity: 1;
        transition: transform 150ms linear, opacity 150ms linear;
        transform: scaleY(1);
    }

    &__wrapper {
        max-width: 1260px;
        margin: auto;
        position: relative;
    }

    &__contentWrapper {
        animation: 150ms linear 0ms 1 fadeInContent forwards;
    }

    &__loaderWrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &--isLoading {
        &:before {
            opacity: 0;
            transform: scaleY(0);
        }
    }
}

.root {
    height: 100%;
}
.prepend {
    width: 100%
}
.wrapper {
    max-width: 1260px;
    margin: auto;
    position: relative;
    padding: 50px 25px;
}
.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
// TODO: is this needed
// [theme.breakpoints.down('xl')]: {
//                                   wrapper: {
//                                       maxWidth: 'initial'
//                                   }}

