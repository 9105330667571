.button {
    right: 24px;
}
.item {
display: inline-block;
width: 50%;
margin: 24px 0 0 0;
}
.item2 {
display: inline-block;
width: 100%;
margin: 24px 0 0 0;
}
.hidden {
visibility: hidden;
position: absolute;
z-index: -1;
pointer-events: none;
}
.spinner-root {
margin: auto;
width: 10%;
}
.spinner{
display: inline-block;
width: 60px;
height: 60px;
}