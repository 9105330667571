.root {
  margin-top: 64px;
  width: 100%;
  background-color: #FFDC4A;
  position: fixed;
  z-index: 1101;
  padding-left: 48px;
}
.container {
  border-bottom: 1px #EEE solid;
  padding: 16px 40px;
  font-size: 16px;
}
.container2 {
  border-bottom: 1px #EEE solid;
  padding: 16px 40px;
  margin-top: 30px;
}
.item {
  display: inline-block;
  width: 25%;
  margin: 16px 20px;
}
