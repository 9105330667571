.root {
  font-weight: 300;
  line-height: 28px
}
.checkList {
  list-style: none;
  margin-left : -41px;
  word-break: break-word;

}
.bulletList {
  list-style: none;
  margin-left: -24px;
  list-style-type: disc;
  word-break: break-word;
}
.numberedList {
  margin-left: -24px;
  word-break: break-word;

}
.checkListItem {
  margin-bottom: 16px;
  word-break: break-word;
  &:before {
    content: "✓";
    display: inline-block;
    margin: 0 20px 0 0;
  }
}
.bulletListItem {
  padding-left: 16px;
  margin-bottom: 16px;
  word-break: break-word;
}
.numberedListItem {
  padding-left: 16px;

  margin-bottom: 16px;
  word-break: break-word;
}