.new-features-bar {
    background-color: rgb(255, 220, 74);
    border-radius: 4px;

    i {
        margin: 15px;
    }

    .new-features-text {
        text-decoration: none;
        flex-grow: 2;

        p {
            margin-top: 18px;
        }
    }

}

.spinnerRoot {
    left: 24px;
    bottom: 80px;
    position: absolute;
}
.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
}
.content {
    padding-top: 24px;
}
.section {
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.sectionTitle {
    display: block;
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 8px;
}
.totals {
    margin: 0 -8px;
}
.tableCardTitle {
    display: inline-block;
    width: calc(50% - 16px);
    margin-left: 8px;
    margin-right: 8px;
}
.tableCardItems {
    margin: 0 -8px;
}
.table {
    overflow: visible;
}
.tableRow.tableRow {
    user-select: none;
    cursor: pointer;
    height: 48px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.12);
    }
}
.tableCell.tableCell {
    padding: 0px;
    font-size: 12px;
    white-space: nowrap;
    vertical-align: middle;
&:first-child {
    padding-left: 24px;
}
    &:last-child {
        text-align: right;
        padding-right: 24px;
    }
    & > * {
        vertical-align: middle;
    }
}
.tableCellTitle {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 50px);
    font-size: 12px;
}
.initialCircle {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #4489FE;
    color: white;
    font-size: 10px;
    overflow: hidden;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    margin-right: 18px;
}
.cumelaLogo {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}
.finishedCount {
    display: inline-block;
    padding: 4px;
    background-color: #FFA621;
    border-radius: 2px;
    color: white;
    min-width: 40px;
    line-height: 24px;
    text-align: center;
}
.finishedNone {
    background-color: #EA462F;
}
.finishedAll {
    background-color: #47CF21;
}
.finishedNoneToolboxes {
    background-color: #C9C9C9;
    display: inline-block;
    border-radius: 2px;
    padding: 4px;
    color: white;
    min-width: 40px;
    text-align: center;
}
.finishedToolboxes {
    background-color: #47CF21;
    display: inline-block;
    border-radius: 2px;
    padding: 4px;
    color: white;
    min-width: 40px;
    text-align: center;
}
.NotFinishedWPI {
    background-color: #C9C9C9;
}

.version {
    // TODO: use variables marginTop: theme.spacing(10);
    // TODO: use variables marginBottom: theme.spacing(10);
    // marginLeft: auto;
    // marginRight: auto;
    display: block;
    width: 100%;
    font-size: 12px;
    padding: 0;
    text-align: center;
& > img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
}
& > p {
    font-family: filson-soft;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
}
& > span {
    vertical-align: middle;
}
}
.vvlogo {
    height: 64px;
    position: fixed;
    right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;

    box-sizing: border-box;
    top: 0;
    z-index: 1200;
}
.newFeaturesBar {
    // TODO: use variables: backgroundColor: theme.palette.primary[500];
    border-radius: 4px;
    & i {
        margin: 15px;
    }
}
.newFeaturesText {
    text-decoration: none;
    flex-grow: 2;
& p {
    margin-top: 18px;
}
}
.contentColor {
    color: black;
}