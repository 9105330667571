@use "../../lib/themes/theme.scss" as theme;
.button {
  position: fixed;
  top: 84px;
  right: 36px;
  z-index: theme.$zindex + 1;
}
//[theme.breakpoints.down('lg')]: {
//                                button: {
//                                  right: '24px',
//                                },
//                              },
