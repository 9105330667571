.root {
  background-color: #fff;
  padding: 48px;
}
.logo {

}
.header {
  background-color: #fff;
}
.title {

}
.message {

}
.content {
  width: 90%;
  background-color: #fff;
  margin: 24px;
}
.pinCode {
  text-align: center;
  width: 100%;
}
.pinNumber {
    border: 2px solid #eee;
    border-radius: 8px;
    display: inline-block;
    font-size: 40px;
    height: 64px;
    line-height: 64px;
    margin: 16px;
    width: 64px
}
.error {

}
.headerContent {

}
