.root {
  display: table-header-group;
  z-index: 1101;
}
.row {
  width: 100%;
  max-width: 1260px;
  height: 52px;
  display: table-row;
  margin: auto;
  padding-top: 4px;
  // TODO from theme theme.palette.primary.A500
  color: #ff0;
  font-weight: 600;
  font-size: 12px;
}
.cell {
  overflow: visible;
}
.actions {
  opacity: 1;
  padding-right: 0 !important;
}
// TODO: what the hell is this
//[theme.breakpoints.down('lg')]: {
//                                actions: {
//                                  opacity: '0;
//                                },
//                              row: {
//                                maxWidth: 'initial;
//                              },
//                              },
