@use "../../../lib/themes/theme" as theme;
.button.button {
  position: fixed;
  top: 34px;
  right: 36px;
  z-index: theme.$zindex + 1,
}
//[theme.breakpoints.down('lg')]: {
//                                button: {
//                                  right: '24px',
//                                },
//                              },
