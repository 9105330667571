.FileUpload {
  &-card {
    padding: 20px;
    margin: 0.5rem 0 1rem 0;
  }
  h2 {
    font-size: 24px;
  }
  &-input {
    overflow: auto;
  }

  form {
    background: rgb(245, 245, 245);
    padding: 20px;
    border: 1px solid black;
  }

  button {
    float: right;
  }
}

.file-field {
  position: relative;
}

.input-field {
  position: relative;
  margin-top: 1rem;
}

.file-field .file-path-wrapper {
  overflow: hidden;
  padding-left: 10px;
}
.uploadButton {
  float: right;
}