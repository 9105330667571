.button {
    right: 24px;
}
.item {
    display: inline-block;
    width: 50%;
    margin: 24px 0 0 0;
}
.item2 {
    display: inline-block;
    width: 100%;
    margin: 24px 0 0 0;
}