.root {
  padding-top: 122px;
}
.container {
  border-bottom: 1px #EEE solid;
  width: 100%;
}
.itemLeft {
  display: inline-block;
  width: 60%;
  vertical-align: top;
  padding-right: 10%;
}
.itemRight {
  display: inline-block;
  width: 30%;
}
.item3o {
  display: inline-block;
  width: 33%;
  padding: 16px 0;
  itemDescription {
    margin-top: -5px;
  }
word-break: break-all;
}
.item4v {
  display: block;
  width: 100%;
  padding: 16px 0;
  margin-top: -5px;
  word-break: break-all;
}
.divider {
  margin-top: 24px;
  margin-bottom: 28px;
  flex: 1 0 100%;
}