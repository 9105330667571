.myContainer {
    padding-left: 16px;
    padding-right: 16px;
}
.editButton {
    float: right;
    position: relative;
    bottom: 12px;
}
.item {
    display: inline-block;
    width: 50%;
    margin: 16px 0;
}