@use "../../../lib/themes/theme.scss" as theme;
.root {
  flex-direction: column;
  padding: 32px 16px;
}
.block {}
.paper {
  padding: 16px;
}
.item {
  display: inline-block;
  width: 50%;
  padding: 16px 0;
}
