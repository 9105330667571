.root {
  font-weight: 300;
}
.list {
  list-style: none;
}
.photo {
  width: auto;
  height: auto;
  max-width: 400px;
  max-height: 400px;
}
.photomobile {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}
.crop {
  width: 300px;
  height: 180px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-left: -40px;
  background-size: cover;
}
.desktop {
  display: block;
  justify-content: center;
  max-height:1000px;
  margin-bottom: 50px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
