.root {
  display: inline-block;
  font-size: 16px;
}
.seperator {
  // TODO: get color from theme: theme.palette.text.primary,
  color: #000;
  font-size: 20px;
  vertical-align: middle;
  padding: 8px;
}
.link {
  vertical-align: middle;
  // TODO: get color from theme: theme.palette.text.primary
  color: #000;
}
