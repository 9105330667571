.root {
  position: relative;
  padding-right: 40px;
}
.dropdownButton {
  border-radius: 0 2px 2px 0;
  min-width: 40px;
  padding: 8px;
  clip: rect(0, 3000px, 3000px, 0);
  position: absolute;
  height: 100%;
  right: 0;
  top:0;
}
.normalButton {
  border-right: 1px solid rgba(0,0,0,0.1);
  border-radius: 2px 0 0 2px;
}
