@media (max-width: 768px) {

  #form {
    margin: auto !important;
    width: 100% !important;
  }

  #loginButton {
    width: 100% !important;
  }

  #headerTitle {
    margin: auto !important;
  }
  .dropdown{
    right: 0;
  }
}
.loginform {
  width: 387px;
  margin: auto;
  margin-top: 200px;
}
.title {
  text-align: center;
  font-size: 36px;
  font-family: filson-soft, sans-serif;
}
.root {
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}
.paper {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 30px;
}
.button {
  width: 100%;
}
.dropdown {
  background-color: transparent;
  right: 250px;
  top: 0;
  position: absolute;
}