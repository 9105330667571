@use "../../lib/themes/theme.scss" as theme;
@media (max-width: 650px) {

  #toolboxBlocks {
    width: 100% !important;
  }

  #toolboxText {
    width: calc(100vw - 180px) !important;
  }
  .listItemContent {
    float: left;
    width: 150px;
}

}

.root {
    margin-top: 64px;
    width: 100%;
    background-color: theme.$primary;
    position: fixed;
    z-index: 1101;
    padding-left: 48px;
}
.content {
    padding-top: 24px;
}
.vvlogo {
    height: 64px;
    position: fixed;
    right: 16px;
    padding: 8px;
    box-sizing: border-box;
    top: 0;
    z-index: 1200;
}
.listItem {
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 2px solid #ECECEC;
    //width: 610px;
    height: 92px;
    margin: auto;
    margin-bottom: 16px
}
.listItemIcon {
    width: 75px;
    height: 100%;
    background-repeat: no-repeat;
    float: left;
    background-position: center;
    background-size: 56px 56px;
}
.listItemArrow {
    float: right;
    margin-right: 15px;
    margin-top: 15px;
}
.listItemContent {
    float: left;
}
.listItemTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.listItemQuestions {
    opacity: 0.5;
    line-height: 2px;
    width: 450px;
}
.listItemDate {
    opacity: 0.5;
    line-height: 6px;
    width: 450px;
}
.noItemsFound {
    margin-top: 30vh
}
.noItemsFoundTitle {
    text-align: center;
    opacity: 0.4;
}
.deployIcon {
    margin: auto;
    width: 100%;
    height: 64px;
    opacity: 0.4;
}