.filterheader{
    align-items: center;
    display: flex;
    z-index: 100;
    cursor: pointer;
    padding: 8px 16px !important;

    border-radius: 2px !important;

    line-height: 16px;
    padding: 10px 17px;
    margin: 0;
}

.filterheader:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.filtertitle {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    color: #9b9b9b;
    letter-spacing: 0;
}
.filtertext {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0;
    margin-left: 8px;

    max-width: fit-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
