.root {
  position: relative;
}
.items {
  margin-top: 8px;
  display: inline-block;
}
.item {
  margin-top: 16px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 8px;
  }
}
.addButton {
  margin: 16px 0 0 -16px;
  display: inline-block;
}
