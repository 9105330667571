.root {
  margin-top: 64px;
  width: 100%;
  background-color: #FFDC4A;
  position: fixed;
  z-index: 1101;
  padding-left: 48px;
}
.button.button {
  position: fixed;
  top: 84px;
  right: 36px;
  // TODO: get from theme?
  z-index: 1102;
}
.selectableSelect {
  min-height: 350px;
}
// TODO
//[theme.breakpoints.down('lg')]: {
//                                button: {
//                                  right: '24px',
//                                },
//                              },
