.sideMenuLogo{
    height: 71.5px;
    padding: 15px 16px;
    img{
        height: 100%;
        width: auto;
    }
}
.icon{
    color: #000
}