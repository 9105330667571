@media (max-width: 650px) {

  #toolboxBlocks {
    width: 100% !important;
  }

  #toolboxText {
    width: calc(100vw - 180px) !important;
  }

}
.muiFixed{
  position: fixed;
  top: 100px;
  right: 32px;
}
