@use "../../lib/themes/theme.scss" as theme;
.button {
  position: fixed;
  top: 84px;
  right: 50px;
  z-index: theme.$zindex + 1;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
}
//[theme.breakpoints.down('lg')] {
//                                button {
//                                  right: 24px;
//                                }
//                              }
.finishedCount {
  display: inline-block;
  background-color: #FFA621;
  border-radius: 2px;
  padding: 4px;
  color: white;
  min-width: 40px;
  text-align: center;
}
.finishedNone {
  background-color: #EA462F;
}
.finishedAll {
  background-color: #47CF21;
  height: 16px;
}
.hidden {
  display: none;
}
.everythingOk {
  background-color: #D3D3D3;
}
.notComplete {
  background-color: #C9C9C9;
}