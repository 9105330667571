.body.body {
  &:after {
    height: 48px;
    display: block;
  }
}
.toolbox.toolbar {
  display: table;
  align-items: center;
}
.table.table {
  table-layout: fixed;
  overflow: visible;
}
.actions.actions {
  position: relative;
  top: 1px;
  padding-right: 0 !important;
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
}
.actionMenu.actionMenu {
  padding-left: 0;
  padding-right: 0;
}
