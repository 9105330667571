@use "../../lib/themes/theme.scss" as theme;
.headerTabs{
    margin-top: 64px;
    width: 100%;
    position: fixed;
    z-index: 1101;
    padding-left: 48px;
    background-color: theme.$primary;
}
.innerTab.Mui-selected{
    color:black !important;
}