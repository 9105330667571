@use "../../../lib/themes/theme.scss" as theme;
.root {}
.text {
  width: 100%;
  margin-bottom: 24px;
}
.dialog{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.voornaam.voornaam {
  display: inline-block;
  width: 30%;
  margin-right: 8px;
  vertical-align: middle;
  height: 76px;
}
.languageSelect {
  margin-bottom: 20px;
  width: 100%;
}
.tussenvoegsel.tussenvoegsel {
  display: inline-block;
  width: 20%;
  margin-right: 8px;
  vertical-align: middle;
  height: 76px;
}
.achternaam.achternaam {
  display: inline-block;
  width: calc(50% - 16px);
  vertical-align: middle;
  height: 76px;
}
.telefoon.telefoon {
  display: inline-block;
  width: 30%;
  margin-right: 8px;
  vertical-align: middle;
  height: 76px;
}
.email.email {
  display: inline-block;
  width: calc(70% - 8px);
  vertical-align: middle;
  height: 76px;
}
