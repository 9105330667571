.root {
  padding: 0;
  margin: 64px;
}
.errorMessage {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
}
.sectorInput {
  height: auto;
}
